// Converts strings to URL friendly string
// The characters from url below not included in this list were manually tested and did not break anything.
// https://devnet.kentico.com/docs/6_0/devguide/index.html?url_format_and_configuration.htm
export default function(word: string) {
	return word.toLowerCase()
		.replace(/ /g, '-')
		.replace(/\//g, '-')
		.replace(/&/g, 'and')
		.replace(/'/g, '')
		.replace(/:/g, '')
		.replace(/"/g, '')
		.replace(/\*/g, '')
		.replace(/</g, '')
		.replace(/>/g, '')
		.replace(/\|/g, '')
		.replace(/%/g, '')
		.replace(/\./g, '')
		.replace(/#/g, '')
		.replace(/\+/g, '')
		.replace(/=/g, '')
		.replace(/„/g, '')
		.replace(/“/g, '');
};
