<template>
	<router-view />
</template>

<script lang="ts">
	import { isPrerender } from '@cognitoforms/utils/utilities';
	import type { SiteManifest, SiteManifestJson } from '@cognitoforms/utils/manifest-helpers';
	import { buildManifest, fetchContentVersion, fetchManifestJson, fetchVersionMetadata } from '@cognitoforms/utils/manifest-helpers';
	import type { PropType } from 'vue';
	import { defineComponent } from 'vue';

	async function refreshSiteManifest(contentVersion: number) {
		const manifestJson = (await Promise.all([
			'dashboard',
			'templates',
			'industries',
			'form-types',
			'product',
			'blog',
			'features',
			'support',
			'company',
			'legal',
			'newsroom',
			'industry-guides'
		].map(async (section) => ({ [section]: await fetchManifestJson(section, contentVersion) }))))
			.reduce((siteManifest, sectionManifest) => Object.assign(siteManifest, sectionManifest), {}) as SiteManifestJson;

		const manifest = buildManifest(manifestJson);

		// mainly for debugging
		for (const [, m] of Object.entries(manifest))
			m['version'] = contentVersion;

		return manifest;
	}

	async function getContentVersion() {
		// always use the latest content version when prerendering
		if (isPrerender())
			return fetchContentVersion();

		const versionMetadata = await fetchVersionMetadata();
		// if the app specifies a prerender content version, make sure to load the corresponding manifest
		return versionMetadata.PrerenderContentVersion > 0 ? versionMetadata.PrerenderContentVersion : fetchContentVersion();
	}

	export default defineComponent({
		provide() {
			return {
				siteManifest: (this as any).siteManifest
			};
		},
		async beforeRouteUpdate(to, from, next) {
			// loadManifest will either be an anonymous function or a boolean
			const shouldLoadManifest = typeof to.meta.loadManifest === 'function' ? to.meta.loadManifest() : to.meta.loadManifest;

			if (to.meta.auth === 'required' && !shouldLoadManifest) {
				next();
				return;
			}

			const contentVersion = await getContentVersion();

			// if the content version is greater than the currently cached content version, pull the production manifest
			if (contentVersion > this.contentVersion || this.siteManifest === null)
				to.params.siteManifest = await refreshSiteManifest(contentVersion) as any;
			else
				to.params.siteManifest = this.siteManifest as any;

			to.params.contentVersion = contentVersion as any;

			next();
		},
		async beforeRouteEnter(to, from, next) {
			// loadManifest will either be an anonymous function or a boolean
			const shouldLoadManifest = typeof to.meta.loadManifest === 'function' ? to.meta.loadManifest() : to.meta.loadManifest;

			if (to.meta.auth === 'required' && !shouldLoadManifest) {
				next();
				return;
			}

			const contentVersion = await getContentVersion();
			to.params.contentVersion = contentVersion as any;
			to.params.siteManifest = await refreshSiteManifest(contentVersion) as any;

			next();
		},
		inheritAttrs: false,
		props: {
			contentVersion: { type: Number, required: false, default: 0 },
			siteManifest: { type: Object as PropType<SiteManifest>, default: undefined }
		}
	});
</script>
