export default [
	{
		path: '/organization/new', component: ()=> import('src/views/settings/CreateOrganization.vue'),
		name: 'new-organization',
		props: true,
		meta: {
			isRouterLink: true,
			auth: 'required'
		}
	}
];