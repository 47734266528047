// Capitalize first letter in string
import urlize from './urlize';

export { urlize };
export function capitalize(word: string) {
	return word.charAt(0).toUpperCase() + word.slice(1);
};

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export function getDate(date: number | string | Date) {
	const d = new Date(date);
	return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
};

export function isPrerender() {
	return typeof window === 'undefined';
}

export function isWithinFrame() {
	if (isPrerender()) return false;
	// https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t/326076#326076
	try {
		return window.self !== window.top;
	}
	catch (e) {
		return true;
	}
}

export function slugify(s: string) {
	// Ensure the slugification of headers and TOC links is the same. Both use this function.
	// https://github.com/valeriangalliat/markdown-it-anchor/blob/e276fe53e259bcd2bf6045b6093f82d3cd606f8c/index.js#L1
	// Also remove special chars, some html entities, and repeating/trailing dashes
	return encodeURIComponent(
		String(s)
			.trim()
			.toLowerCase()
			.replace(/&shy;/g, '-')
			.replace(/&nbsp;/g, '-')
			.replace(/&ndash;/g, '-')
			.replace(/&mdash;/g, '-')
			.replace(/\s+/g, '-')
			.replace(/[^a-zA-Z0-9]/g, '-')
			.replace(/-+/g, '-')
			.replace(/-$/g, '')
	);
}