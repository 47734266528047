import { changeEmail } from '@cognitoforms/api/services/authentication';

export default [{
	path: '/auth',
	component: () => import(/* webpackChunkName: 'Auth' */ 'src/layouts/Auth.vue'),
	children: [
		{
			path: '/login',
			name: 'login',
			component: () => import(/* webpackChunkName: 'Auth' */ './Login.vue'),
			props: route => ({ ...route.query, ...route.params }),
			meta: {
				title: 'Log In'
			}
		},
		{
			path: '/signup',
			name: 'signup',
			component: () => import(/* webpackChunkName: 'Auth' */ './Signup.vue'),
			props: route => ({ ...route.query, ...route.params }),
			meta: {
				title: 'Sign Up'
			}
		},
		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: () => import(/* webpackChunkName: 'ForgotPassword' */ './ForgotPassword.vue'),
			meta: {
				title: 'Recover Password'
			}
		},
		{
			path: '/reset-password',
			name: 'reset-password',
			component: () => import(/* webpackChunkName: 'ResetPassword' */ './ResetPassword.vue'),
			props: route => ({ ...route.query, ...route.params }),
			meta: {
				title: 'Reset Your Password'
			}
		},
		{
			path: '/verify-email-change',
			name: 'verify-email-change',
			component: () => import(/* webpackChunkName: 'VerifyEmailChange' */ './VerifyEmailChange.vue'),
			props: route => ({ ...route.query, ...route.params }),
			beforeEnter: (to, from, next) => {
				// If the user is verifying an email address change, change their email and continue to My Account page.
				if (to.query.code) {
					changeEmail(to.query.code)
						.then(res => {
							// successful log in
							next({ path: '/myaccount', query: { code: to.query.code } });
						}).catch(() => {
							next({ path: '/verify-email-change', query: { invalidCode: true } }); // Show error message if invalid
						});
				}
				else {
					next();
				}
			}
		},
		{
			path: '/invitation',
			name: 'accept-invite',
			component: () => import(/* webpackChunkName: 'AcceptInvite' */ './AcceptInvite.vue'),
			props: route => ({ ...route.query, ...route.params }),
			meta: {
				title: 'Log In'
			}
		},
		{
			path: '/accept-invite-login',
			name: 'accept-invite-login',
			component: () => import(/* webpackChunkName: 'AcceptInviteLogin' */ './AcceptInviteLogin.vue'),
			props: route => ({ ...route.query, ...route.params }),
			meta: {
				title: 'Log In',
				showBackButton: true
			}
		},
		{
			path: '/pending-invites',
			name: 'pending-invites',
			component: () => import(/* webpackChunkName: 'PendingInvites' */ './PendingInvites.vue'),
			props: route => ({ ...route.query, ...route.params }),
			meta: {
				title: 'Join Organization',
				content: 'Cognito Forms, a free online form builder that helps you collect information and payments. Easily create feedback forms, payment forms, registration forms, and much more.'
			}
		},
		{
			path: '/api-connection',
			name: 'api-connection',
			component: () => import(/* webpackChunkName: 'ApiConnection' */ './ApiConnection.vue'),
			props: route => ({ ...route.query, ...route.params })
		}
	]
}];