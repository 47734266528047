import { CognitoConfiguration } from './clientside-configuration';
import { urlize } from './utilities';
export function getTagIdentifier(section, cfid) {
	return section + cfid;
}

type ManifestJson = {
	section: string;
	articles: Article[];
};

export type Link = {
	cfid: number,
	section: SiteSection,
	title: string
}

export type Article = {
	cfid?: string;
	code?: string;
	route: string;
	author?: string;
	canonicalUrl?: string;
	breadcrumbs?: string[];
	order?: number;
	title?: string;
	htmltitle?: string;
	menuTitle?: string;
	cdnPath?: string;
	contentUrl?: string;
	links?: Link[];
	slug?: string;
	isLandingPage?: 'True' | 'False';
	tags?: string[];
	published?: string;
	modified?: string;
	description?: string;
	htmldescription?: string;
	socialimage?: string;
	illustration?: string;
	video?: string;
	metapage?: string;
	zendesk?: 'true' | 'false';
	hidden?: 'true' | 'false';
};

type Lookup<T> = { [key in number | string]: T }

export type SectionManifest = {
	section: string;
	list: {
		all: Article[];
		byParentCfid: Lookup<Article[]>;
		byTag: Lookup<Article[]>;
	};
	lookup: {
		byCfid: Lookup<Article>;
		byCode: Lookup<Article>;
	};
};

export type TemplateSectionManifest = SectionManifest & {
	findByTagAndTitle(tagName: any, title: any): any;
	list: {
		all: Article[];
		byParentCfid: Lookup<Article[]>;
		byTag: Lookup<Article[]>;
		byCategory: Lookup<Article[]>;
		byRanking: Lookup<Article[]>;
	};
};

export type SiteManifestJson = {
	dashboard: ManifestJson;
	templates: ManifestJson;
	industries: ManifestJson;
	'form-types': ManifestJson;
	product: ManifestJson;
	blog: ManifestJson;
	features: ManifestJson;
	support: ManifestJson;
	company: ManifestJson;
	legal: ManifestJson;
	newsroom: ManifestJson;
	'industry-guides': ManifestJson;
}

export type SiteSection = keyof SiteManifestJson;

export type SiteManifest = {
	dashboard: SectionManifest;
	templates: TemplateSectionManifest;
	industries: SectionManifest;
	'form-types': SectionManifest;
	product: SectionManifest;
	blog: SectionManifest;
	features: SectionManifest;
	support: SectionManifest;
	company: SectionManifest;
	legal: SectionManifest;
	newsroom: SectionManifest;
	'industry-guides': SectionManifest;
	tags: {
		list: {
			all: Article[];
			// category => tag[]
			byCategory: {
				industry: Article[];
				type: Article[];
				feature: Article[];
			};
		};
	};
	lookup(section: string, cfid: number): any;
};

export function buildManifest({
	dashboard,
	templates,
	industries,
	'form-types': formTypes,
	product,
	blog,
	features,
	support,
	company,
	legal,
	newsroom,
	'industry-guides': industryGuides
}: SiteManifestJson): SiteManifest {
	const templateManifest = buildArticleManifest(templates);

	const manifest = {
		'dashboard': buildArticleManifest(dashboard),
		'templates': {
			...templateManifest,
			list: {
				...templateManifest.list,
				byCategory: {
					industry: [],
					type: [],
					feature: []
				}
			},
			findByTagAndTitle(tagName, title) {
				const tag = manifest.tags.list.all.find(t => t.slug === urlize(tagName));
				return (tag
					? manifest.templates.list.byTag[tag.identifier]
					: manifest.templates.list.all)
					.find(t => t.slug === urlize(title));
			}
		},
		'industries': buildArticleManifest(industries),
		'form-types': buildArticleManifest(formTypes),
		'product': buildArticleManifest(product),
		'blog': buildArticleManifest(blog),
		'features': buildArticleManifest(features),
		'support': buildArticleManifest(support),
		'company': buildArticleManifest(company),
		'legal': buildArticleManifest(legal),
		'newsroom': buildArticleManifest(newsroom),
		'industry-guides': buildArticleManifest(industryGuides),
		'tags': {
			list: {
				all: [],
				// category => tag[]
				byCategory: {
					industry: [],
					type: [],
					feature: []
				}
			}
		},
		lookup(section, cfid) {
			return this[section] ? this[section].lookup.byCfid[cfid] : null;
		}
	} as SiteManifest;

	manifest.templates.list.all.forEach(cleanupTemplateLinks);

	const tagSets = {
		industry: new Set(),
		type: new Set(),
		feature: new Set()
	};

	for (const template of manifest.templates.list.all) {
		template.slug = urlize(template.title);

		// group items from industries/features/form-types
		for (const { cfid, section } of template.links) {
			if (!!manifest[section] && !!categoryForSection(section)) {
				const tag = mapTagBySection(section, cfid);
				mapTemplateByTagSection(section, template);
				mapTemplateByTagCfid(tag.identifier, template);
			}
		}

		if (template.rankings) {
			template.rankings = template.rankings.map(function (r) {
				return {
					link: r[0],
					rank: parseInt(r[1])
				};
			});

			template.rankings.forEach(ranking => {
				if (ranking.link) {
					const left = ranking.link.indexOf('[') + 1;
					const right = ranking.link.indexOf(']') - 1;
					const title = ranking.link.substr(left, right);
					ranking.slug = urlize(title);

					mapTemplateByRanking(ranking.slug, template);
				}
			});
		}
	}

	// convert sets to arrays
	for (const category in manifest.tags.list.byCategory) {
		manifest.tags.list.byCategory[category] = Array.from(tagSets[category]).sort((a: any, b: any) => a.order - b.order);
		manifest.tags.list.all.push(...manifest.tags.list.byCategory[category]);
	}

	return manifest;

	function categoryForSection(section) {
		switch (section) {
			case 'industries': return 'industry';
			case 'form-types': return 'type';
			case 'product': return 'feature';
		}
	}

	function mapTagBySection(section, cfid) {
		const tag = manifest[section].lookup.byCfid[cfid];
		tag.category = categoryForSection(section);
		tag.slug = tag.canonicalUrl.split('/').pop().toLowerCase();
		tag.identifier = getTagIdentifier(section, cfid);
		const category = categoryForSection(section);
		tagSets[category].add(tag);
		return tag;
	}

	function mapTemplateByTagSection(section: string, template: Article) {
		manifest.templates.list.byCategory[categoryForSection(section)].push(template);
	}

	function mapTemplateByTagCfid(cfid: number, template: Article) {
		const taggedTemplates = manifest.templates.list.byTag[cfid] || [];
		taggedTemplates.push(template);
		manifest.templates.list.byTag[cfid] = taggedTemplates;
	}

	function mapTemplateByRanking(slug: string, template: Article) {
		if (!template.rankings)
			return;

		// Get set rankings for slug
		const rankedTemplates = manifest.templates.list.byRanking[slug] || [];

		// Add template to list
		rankedTemplates.push(template);
		manifest.templates.list.byRanking[slug] = rankedTemplates;
	}

	function cleanupTemplateLinks(template: Article) {
		for (const l of template.links)
			l.section = urlize(l.section);
	}
}

export function buildArticleManifest(json: ManifestJson): SectionManifest {
	const manifest = {
		section: json.section.substr(0, 1).toUpperCase() + json.section.substr(1),
		list: {
			// Article[]
			all: json.articles,
			// cfid => Article[]
			byParentCfid: {},
			byTag: {},
			byRanking: {}
		},
		lookup: {
			// cfid => Article
			byCfid: {},
			byCode: {}
		}
	} as SectionManifest;

	augmentArticles();
	mapByCfid();
	mapByCode();
	groupByTag();
	groupByParent();

	return manifest;

	function groupByParent() {
		for (const article of json.articles) {
			const parentCfid = article.breadcrumbs[article.breadcrumbs.length - 1] || null;
			const children = manifest.list.byParentCfid[parentCfid] || [];
			children.push(article);
			manifest.list.byParentCfid[parentCfid] = children;
		}

		// Ensure hierarchical lists are sorted
		Object.keys(manifest.list.byParentCfid)
			.map(key => manifest.list.byParentCfid[key])
			.sort((a: any, b: any) => a.order - b.order);
	}

	function groupByTag() {
		for (const article of json.articles.filter(a => a.tags)) {
			for (const tag of article.tags) {
				const byTag = manifest.list.byTag[tag] || [];
				byTag.push(article);
				manifest.list.byTag[tag] = byTag;
			}
		}
	}

	function mapByCfid() {
		for (const article of json.articles)
			manifest.lookup.byCfid[article.cfid] = article;
	}

	function mapByCode() {
		for (const article of json.articles) {
			manifest.lookup.byCode[article.code] = article;
		}
	}

	function augmentArticles() {
		for (const article of json.articles) {
			article.cfid = `${article.cfid}`;
			article.menuTitle = article.menuTitle || article.title;
			article.contentUrl = CognitoConfiguration.ContentUrl + json.section + article.cdnPath;
			article.breadcrumbs = article.breadcrumbs.map(b => b.toString());
		}
	}
}

export async function fetchContentVersion(): Promise<number> {
	const version = Number(await (await fetch(CognitoConfiguration.ContentUrl + 'version?rnd=' + (new Date().getTime()).toString())).text());
	return version;
}

const manifestCache = new Map<string, unknown>();
export async function fetchManifestJson(section: string, version: number): Promise<unknown> {
	const cacheKey = `${section}-${version}`;
	if (manifestCache.has(cacheKey)) {
		return manifestCache.get(cacheKey);
	}

	const json = await fetch(`${CognitoConfiguration.ContentUrl}${section}/manifest-${version}.json`)
		.then(res => res.json());
	manifestCache.set(cacheKey, json);

	return json;
}

type VersionMetadata = {
	SemanticVersionHash: string;
	SemanticVersion: string;
	PrerenderContentVersion: number;
};

export function fetchVersionMetadata(): Promise<VersionMetadata> {
	return fetch(`${CognitoConfiguration.SiteUrl}version-metadata`)
		.then(res => res.json());
}